import React from "react";
import { useTranslation } from "react-i18next";

export default function Education() {
  const { t } = useTranslation(['education']);
  const edu = t('edu', { returnObjects: true });
  const trening = t('trening', { returnObjects: true });
  return (
    <>
      <div className="section">
        <div className="container cc-education">
          <div className="h4 text-center mb-4 title">{t('education')}</div>
          {edu && edu.map((v, i) => {
            return (
              <div className="card" key={i}>
                <div className="row">
                  <div className="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
                    <div className="card-body cc-education-header">
                      <p>{v.date}</p>
                      <div className="h5">{v.wodeba}</div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
                    <div className="card-body">
                      <div className="h5">{v.org}</div>
                      <p className="category">{v.kvalif}</p>
                      <p>{v.fakulteti}<br />{v.spec}<br /> {v.diplomi && (<a href={v.diplomi} target="_blanck">{t('diplomi')}</a>)}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="section">
        <div className="container cc-education">
          <div className="h4 text-center mb-4 title">{t('trenings')}</div>
          {trening && trening.map((v, i) => {
            return (
              <div className="card"  key={i}>
                <div className="row">
                  <div className="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
                    <div className="card-body cc-education-header">
                      <p>{ v.date }</p>
                      <div className="h5"></div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
                    <div className="card-body">
                      <div className="h5">{ v.org }</div>
                      <p className="category">{ v.kvalif }</p>
                      <p><br />{ v.spec }</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}