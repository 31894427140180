import React from "react";
import { useTranslation } from "react-i18next";

export default function Skill() {
  const { t } = useTranslation(['skils']);
  const web = t('web', { returnObjects: true });
  const desk = t('desk', { returnObjects: true });
  const framework = t('framework', { returnObjects: true });
  const OS = t('OS', { returnObjects: true });
  const tech = t('tech', { returnObjects: true });

  return (
    <>
      <div className="section" id="skill">
        <div className="container">
          <div className="h4 text-center mb-4 title">{t('skill')}</div>
          <div className="card" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <div className="card-body">
              <h2>{t('webprog')}</h2>
              {web && (web.map((v, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc + '%' }}></div><span className="progress-value">{v.proc}%</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name1}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc1 + '%' }}></div><span className="progress-value">{v.proc1}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              )}

            </div>
            <div className="card-body">
              <h2>{t('programming')}</h2>
              {desk && (desk.map((v, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc + '%' }}></div><span className="progress-value">{v.proc}%</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name1}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc1 + '%' }}></div><span className="progress-value">{v.proc1}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              )}
            </div >
            <div className="card-body">
              <h2>{t('framework1')}</h2>
              {framework && (framework.map((v, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc + '%' }}></div><span className="progress-value">{v.proc}%</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name1}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc1 + '%' }}></div><span className="progress-value">{v.proc1}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              )}
            </div >
            <div className="card-body">
              <h2>{t('os')}</h2>
              {OS && (OS.map((v, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc + '%' }}></div><span className="progress-value">{v.proc}%</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name1}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc1 + '%' }}></div><span className="progress-value">{v.proc1}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              )}
            </div >
            <div className="card-body">
              <h2>{t('service')}</h2>
              {tech && (tech.map((v, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc + '%' }}></div><span className="progress-value">{v.proc}%</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="progress-container progress-primary"><span className="progress-badge">{v.name1}</span>
                        <div className="progress">
                          <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10"
                            data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
                            style={{ width: v.proc1 + '%' }}></div><span className="progress-value">{v.proc1}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              )}
            </div >

          </div >
        </div >
      </div >
    </>
  );
}