import React from "react";
import { useTranslation } from "react-i18next";

export default function Experience() {
  const { t } = useTranslation(['experience']);
  const exp = t('exp', { returnObjects: true });
  return (
    <div className="section" id="experience">
      <div className="container cc-experience">
        <div className="h4 text-center mb-4 title">{t('experience')}</div>
        {exp && exp.map((v, i) => {
          return (
            <div className="card" key={i}>
              <div className="row">
                <div className="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
                  <div className="card-body cc-experience-header">
                    <p>{v.date}</p>
                    <div className="h5">{v.org}</div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
                  <div className="card-body">
                    <div className="h5">{v.pos}</div>
                    <p>{v.text}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>

  );
}