import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation(['footer']);
  return (
    <footer className="footer">
      <div className="container text-center">
        <a className="cc-facebook btn btn-link" href="https://www.facebook.com/ramaz.maisashvili" title="Facebook" target="_blank">
          <i className="fa fa-facebook fa-2x " aria-hidden="true"></i>
        </a>
        <a className="cc-twitter btn btn-link " href="https://twitter.com/maisasvili" title="X space" target="_blank">
          <i className="fa fa-twitter fa-2x " aria-hidden="true"></i>

        </a>
        <a className="cc-google-plus btn btn-link" href="https://www.linkedin.com/in/ramazi-maisashvili-045a86183/" title="LinkedIn" target="_blank">
          <i className="fa fa-linkedin-square fa-2x" aria-hidden="true"></i>
        </a>
        <a className="cc-instagram btn btn-link" href="https://www.instagram.com/ramazi.maisashvili/" title="instagram" target="_blank">
          <i className="fa fa-instagram fa-2x " aria-hidden="true"></i>
        </a>
      </div>
      <div className="h4 title text-center">{t('name')} {t('nameEn')}</div>
      <div className="text-center text-muted">
        <p>&copy; {t('ALR')}<br />{t('created')} -
          <a className="credit" href="https://ramazi.pvt.ge" target="_blank">{t('name')}</a>
        </p>
      </div>
    </footer>
  );
}