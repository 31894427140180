import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation(['about']);
    // const lang = localStorage.getItem('lang') || 'ge';
  return (
      <div className="section" id="about">
        <div className="container">
          <div className="card" data-aos="fade-up" data-aos-offset="10">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="card-body">
                  <div className="h4 mt-0 title">{t('about')}</div>
                  <p>{t('hello')}</p>
                  <p>{t('shorttext')}
                    <a href="!#" target="_blank">{t('readmore')}</a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="card-body">
                  <div className="h4 mt-0 title">{t('privateinfo')}</div>

                  <div className="row ">
                    <div className="col-sm-4"><strong className="text-uppercase">{t('email')}</strong></div>
                    <div className="col-sm-8"><a rel='noreferrer' href={`mailto:${t('mail')}`}>{t('mail')}</a></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-4"><strong className="text-uppercase">{t('cinfo')}</strong></div>
                    <div className="col-sm-8"><a rel='noreferrer' href={`tel:${t('phone')}`}>{t('phoneinfo')}</a></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-4"><strong className="text-uppercase">{t('WhatsApp')}:</strong></div>
                    <div className="col-sm-8"><a rel='noreferrer' href={`https://wa.me/${t('phone')}`} target="_blank">{t('phoneinfo')}</a></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-4"><strong className="text-uppercase">{t('addressinfo')}</strong></div>
                    <div className="col-sm-8">{t('address')}</div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-4"><strong className="text-uppercase">{t('lang')}</strong></div>
                    <div className="col-sm-8">{t('langs')}</div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}