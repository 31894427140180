import React from "react";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation(['contact']);
  return (
    <>
      <div className="section" id="contact">
        <div className="cc-contact-information" style={{backgroundImage:" url('images/staticmap.png')"}}>
          <div className="container">
            <div className="cc-contact">
              <div className="row">
                <div className="col-md-9">
                  <div className="card mb-0" data-aos="zoom-in">
                    <div className="h4 text-center title">{t('Contact') }</div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-body">
                          <form action="https://ramazi.pvt.ge/sendMail.php" method="POST" encType="multipart/form-data">
                            <div className="p pb-3"><strong>{t('Fill') }</strong></div>
                            <div className="row mb-3">
                              <div className="col">
                                <div className="input-group"><span className="input-group-addon"><i
                                  className="fa fa-user-circle"></i></span>
                                  <input className="form-control" type="text" name="name" placeholder={t('Name')}
                                    required="required" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col">
                                <div className="input-group"><span className="input-group-addon"><i
                                  className="fa fa-file-text"></i></span>
                                  <input className="form-control" type="text" name="Subject" placeholder={t('Subject')}
                                    required="required" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col">
                                <div className="input-group"><span className="input-group-addon"><i
                                  className="fa fa-envelope"></i></span>
                                  <input className="form-control" type="email" name="_replyto" placeholder={t('Email')}
                                    required="required" />
                                </div>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col">
                                <div className="form-group">
                                  <textarea className="form-control" name="message" placeholder={t('Msg')}
                                    required="required"></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <button className="btn btn-primary" type="submit">{t('Send') }</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-body">
                          <p className="mb-0"><strong>{t('Address')} </strong></p>
                          <p className="pb-2"><a href="https://www.google.com/maps/place/42%C2%B014'11.4%22N+42%C2%B042'34.3%22E/@42.236486,42.709537,683m/data=!3m1!1e3!4m4!3m3!8m2!3d42.236486!4d42.709537?entry=ttu" target="_blank">{t('Address1') }</a></p>
                          <p className="mb-0"><strong>{t('Phone') }</strong></p>
                          <p className="pb-2"><a href={`tel:${t('phone')}`} target="_blank">{t('phoneinfo')}</a></p>
                          <p className="mb-0"><strong>{t('WhatsApp') }</strong></p>
                          <p className="pb-2"><a href={`https://wa.me/${t('phone')}`} target="_blank">{t('phoneinfo')}</a></p>
                          <p className="mb-0"><strong>{t('Email') }</strong></p>
                          <p><a href={`mailto:${t('Email')}`}>{t('mail')}</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}