import React from "react";
import { useTranslation } from "react-i18next";


export default function Profile() {
  const { t } = useTranslation(['profile']);
  // const lang = localStorage.getItem('lang') || 'ge';
  return (
    <><div className="profile-page">
      <div className="wrapper">
        <div className="page-header page-header-small" filter-color="green">
          <div className="page-header-image" data-parallax="true" style={{ backgroundImage: 'url("images/cc-bg-1.jpg")' }} >
          </div>
          <div className="container">
            <div className="content-center">
              <div className="cc-profile-image"> <a rel='noreferrer' href={`#${t('linkname')}`}><img src="images/anthony.jpg" alt={t('name')} /></a></div>
              <div className="h2 title">{t('name')}</div>
              <p className="category text-white">{t('profesion')}</p>
              <a rel='noreferrer' className="btn btn-primary smooth-scroll mr-2" href="#contact" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">{t('order')}</a>
              <a rel='noreferrer' className="btn btn-primary" href="http://ramazi.pvt.ge/CV_Ramazi_Maisashvili.pdf" target="_blank" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">{t('DownloadCV')}</a>
            </div>
          </div>
          <div className="section">
            <div className="container">
              <div className="button-container">
                <a className="btn btn-default btn-round btn-lg btn-icon" href="https://www.facebook.com/ramaz.maisashvili" rel="tooltip noreferrer" target="_blank" title={t('viewfb')}>
                  <i className="fa fa-facebook"></i></a>
                <a className="btn btn-default btn-round btn-lg btn-icon" href="https://twitter.com/maisasvili" rel="tooltip noreferrer" title={t('viewx')} target="_blank">
                  <i className="fa fa-twitter"></i></a>
                <a className="btn btn-default btn-round btn-lg btn-icon" href="https://www.linkedin.com/in/ramazi-maisashvili-045a86183/" rel="tooltip noreferrer" title={t('viewIn')} target="_blank">
                  <i className="fa fa-linkedin-square"></i></a>
                <a className="btn btn-default btn-round btn-lg btn-icon" href="https://www.instagram.com/ramazi.maisashvili/" target="_blank" rel="tooltip noreferrer" title={t('viewinstagram')}>
                  <i className="fa fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}