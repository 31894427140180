import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Snowfall from 'react-snowfall';

// import logo from './logo.svg';
import './App.css';
import Header from './comp/Header';
import Profile from './comp/Profile';
import About from './comp/About';
import Skill from './comp/Skill';
import Experience from './comp/Experience';
import Education from './comp/Education';
import Contact from './comp/Contact';
import Footer from "./comp/Footer";
// import Fireworks1 from "./comp/Fireworks1";

// import Reference from './comp/Reference';

import AOS from 'aos';
import 'aos/dist/aos.css';



export default function App() {
  const [isActive, setIsActive] = useState(false);
  const lang = localStorage.getItem('lang') || 'ge';
  useEffect(() => {
    const today = new Date();
    const start = new Date(today.getFullYear(), 11, 1); // 1 დეკემბერი
    const end = new Date(today.getFullYear() + 1, 0, 15); // 15 იანვარი

    if (today >= start && today <= end) {
      setIsActive(true);

    }
    AOS.init({
    });
  }, []);

  return (
    <>
      <Helmet>
        <html lang={lang === 'ge' ? 'ka' : lang} />
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200" rel="stylesheet" />
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css" rel="stylesheet" />
        <link href="/css/bootstrap.min.css" rel="stylesheet" />
        <link href="/css/main.css" rel="stylesheet" />
      </Helmet>

      {isActive && (<h1 className='holiday lights' style={style}>მხიარულ და ბედნიერ შობა ახალი წელს გისურვებთ!</h1>)}

      <Header />
      <div className="page-content">
        <div>
          <Profile />
          <About />
          <Skill />
          {/* portfolio */}
          <Experience />
          <Education />
          {/* <Reference /> */}
          <Contact />
          <Footer />
        </div>
      </div>
      <Snowfall
        snowflakeCount='500'
        radius={[0.5, 5]}
        speed={[0, 5]}
        wind={[0, 5]}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '99vh',
        }} />
    </>
  );
}

const style = {
  top: '50%',
  left: '0',
  width: '100%',
  height: '100%',
  position: 'fixed',
  textAlign: 'center',
  color: 'red',
  backgroundColor: 'transparent',
  zIndex: 1000
};