import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

const lng=localStorage.getItem('lang')||"ge";

i18n
  .use(HttpBackend) // Backend ჩატვირთვისთვის
  .use(initReactI18next) // React ინტეგრაცია
  .init({
    fallbackLng: lng, // ნაგულისხმევი ენა
    debug: false,
    ns: ["main"], // default namespace საერთო თარგმანებისთვის
    defaultNS: "main", // ნაგულისხმევი namespace
    backend: {
      loadPath: "/locale/{{lng}}/{{ns}}.json", // თარგმანის ფაილების მისამართი
    },
    interpolation: {
      escapeValue: true, // React ავტომატურად იცავს HTML-ს
    },
  });

export default i18n;